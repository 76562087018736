/*
 * @Description:
 * @Author: zhangguoliang
 * @Date: 2021-08-31 11:22:29
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-09-22 16:31:56
 */

export function isMobile(val: string): boolean {
  return /^1\d{10}$/.test(val);
}

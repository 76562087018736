/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 13:50:56
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-12-21 14:40:32
 */

export interface Tag {
  path: string;
  name: string;
  query?: Record<string, string | (string | null)[]>;
}

export interface MenuItem {
  path: string;
  location: string;
  title: string;
  icon?: string;
  hidden?: boolean;
  children?: MenuItem[];
  subMenuKey?: string[];
}

export type RuleObject = {
  required?: boolean;
  trigger: 'blur' | 'change' | ['change', 'blur'];
  message?: string;
  type?: string;
  len?: number;
  max?: number;
  min?: number;
  pattern?: RegExp;
  validator?: (
    rule: RuleObject,
    value: string | Array<string>,
    callback: (msg: string | Error) => unknown
  ) => void;
  whitespace?: boolean;
};

interface ItemConfig {
  label: string;
  prop: string;
  rules?: RuleObject;
}

export interface FormItemConfig {
  type: string;
  slot?: boolean;
  itemConfig: ItemConfig;
  innerConfig?: Record<string, unknown>;
  valueType?: string;
  hidden?: boolean;
}

export type Raw = Record<
  string,
  string | number | Array<string | number> | null
>;

export interface LoginByPassword {
  username: string;
  password: string;
  grant_type: 'password';
  scope: 'all';
  captcha: string;
  uuid: string;
}

export interface LoginBySmsCode {
  mobile: string;
  sms_code: string;
  grant_type: 'sms';
  scope: 'all';
}

export interface BaseResponse<ResultType = any> {
  code: string;
  message: string;
  result: ResultType;
  status: number;
  traceId: null | string;
}

export type Query<T = any> = (T extends Record<string, string>
  ? { [P in keyof T]: T[P] }
  : never) & {
  limit: number;
  page: number;
};

export enum SMS_CODE_TYPE {
  LOGIN = 1,
  REGISTER = 2,
  CHANGE_PASSWORD = 3,
}

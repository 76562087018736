/*
 * @Author: zhangguoliang
 * @Date: 2021-12-02 13:59:23
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-12-16 21:46:57
 */
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      loading: false,
      formState: {} as any,
    };
  },
  // mounted() {
  //   document.addEventListener('keyup', this.enter);
  // },
  // destroyed() {
  //   document.removeEventListener('keyup', this.enter);
  // },
  methods: {
    // enter(e: KeyboardEvent) {
    //   if (e.key === 'Enter') {
    //     this.handleSubmit();
    //   }
    // },
    handleSubmit() {
      (this.$refs['formRef'] as any).validate((valid: boolean) => {
        if (!valid) return;
        this.loading = true;
        this.$store
          .dispatch('login', this.formState)
          .then(() => {
            if (this['checked'] && this.formState.username) {
              localStorage.setItem('username', this.formState.username);
            }
            this.$router.replace({ path: '/' });
          })
          .finally(() => {
            this['captchaCode'] && this['captchaCode']();
            this.loading = false;
          });
      });
    },
  },
});


















































import Vue from 'vue';
import { checkMobile, getSmsCode } from '@/api/auth';
import { isMobile } from '@/utils/validate';
import { countDown } from '@/utils';
import mixin from '@/views/login/mixin';
import { SMS_CODE_TYPE } from '@/interface';

export default Vue.extend({
  mixins: [mixin],
  data() {
    const validateMobile = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'));
      }
      if (!isMobile(value)) {
        return callback(new Error('手机号码格式有误'));
      }
    };
    return {
      formState: {
        mobile: '',
        sms_code: '',
        grant_type: 'sms',
        scope: 'all',
      },
      rules: {
        mobile: [
          {
            required: true,
            validator: validateMobile,
            validateTrigger: 'blur',
          },
        ],
        sms_code: [
          {
            required: true,
            message: '验证码不能为空',
            validateTrigger: 'blur',
          },
        ],
      },
      down: {},
    };
  },
  created() {
    this.down = countDown(60);
  },
  methods: {
    getVerifyCode() {
      if (!isMobile(this.formState.mobile)) {
        this.$message.error('手机号码格式有误');
        return;
      }
      checkMobile(this.formState.mobile, SMS_CODE_TYPE.LOGIN).then(() => {
        this.down['begin']();
        getSmsCode(this.formState.mobile, SMS_CODE_TYPE.LOGIN).then(() => {
          this.$message.success('已发送');
        });
      });
    },
  },
});
